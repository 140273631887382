import { compressToBase64, decompressFromBase64 } from 'lz-string';
import { AuthzType } from '@faroconnect/authz-client';
import { HttpClient } from '@faroconnect/clientbase';
import { BaseServiceAny } from './BaseServiceAny';
import { SubscriptionService } from '@/subscription';
import { BearerToken, localBearerTokenAuthenticationPolicyFactory } from '@/utils/bearertoken';
import { BaseService } from '@/store/services/BaseService';
import { Workspace, IWorkspace } from '@/classes/authz/Workspace';
import {
	FaroSubscriptionCreateWorkspaceOptionalParams,
	FaroSubscriptionOptionalParams,
	FaroSubscriptionUpdateWorkspaceOptionalParams,
	Workspace as RestWorkspace,
	WorkspaceOverview,
} from '@/subscription/generated/client';
import { config } from '@/config';

export interface WorkspaceFeatures {
	WorkspaceId: string;
	Features: string[];
}

const workspaceFromRestType = (rw: RestWorkspace): IWorkspace => {
	let deactivationDate = rw.deactivationDate?.toISOString() || '';
	// We don't want to show '0001-01-01' if the date is not set.
	if (deactivationDate < '1971-01-01') {
		deactivationDate = '';
	}

	return {
		UUID: rw.id,
		Name: rw.name,
		Description: rw.description ?? '',
		UpdateDate: '',
		State: rw.state as AuthzType.WorkspaceState,
		Region: rw.region as AuthzType.WebshareRegion,
		Subscription: rw.subscription ? {
			id: rw.subscription.id,
			expirationDate: deactivationDate || undefined,
			isTrial: rw.subscription.isTrial,
			startDate: rw.subscription.startDate?.toISOString(),
			type: rw.subscription.type,
		} : undefined,
		CreationDate: rw.createdAt?.toISOString(),
		Version: 0,
		// Empty or default values to fit IWorkspace type.
		Class: 'Workspace',
		WebshareDomainName: '',
		WebshareAlias: null,
		WebshareRegion: rw.region as AuthzType.WebshareRegion,
		Owner: '',
		LastVisitDate: '',
		ShareDate: '',
		InitialOwner: '',
		IsDemo: false,
		ErpId: rw.erpId,
		ReadOnly: false,
		XgRedirect: false,
		Notes: rw.notes,
		CommerciallyRelevant: rw.commerciallyRelevant,
		BilledSubscription: rw.billedSubscription,
		BilledModule: rw.billedModule,
	};
};

const workspaceFromOverviewRestType = (rwo: WorkspaceOverview): IWorkspace => {
	// We may have Date objects (if we just called the API) or strings (if we got the response from cache).
	let deactivationDate = typeof rwo.deactivationDate === 'string' ? rwo.deactivationDate : rwo.deactivationDate?.toISOString() || '';
	// We don't want to show '0001-01-01' if the date is not set.
	if (deactivationDate < '1971-01-01') {
		deactivationDate = '';
	}

	return {
		UUID: rwo.id,
		Name: rwo.name,
		Description: rwo.description ?? '',
		UpdateDate: '',
		State: rwo.state as AuthzType.WorkspaceState,
		Region: rwo.cloudRegion as AuthzType.WebshareRegion,
		Subscription: {
			id: '',
			type: rwo.subscriptionType,
			startDate: typeof rwo.subscriptionStartDate === 'string' ? rwo.subscriptionStartDate : rwo.subscriptionStartDate?.toISOString() || '',
			expirationDate: undefined,
			isTrial: rwo.subscriptionIsTrial,
		},
		CreationDate: typeof rwo.createdAt === 'string' ? rwo.createdAt : rwo.createdAt?.toISOString(),
		Version: 0,
		// Empty or default values to fit IWorkspace type.
		Class: 'Workspace',
		WebshareDomainName: '',
		WebshareAlias: null,
		WebshareRegion: rwo.cloudRegion as AuthzType.WebshareRegion,
		Owner: '',
		LastVisitDate: '',
		ShareDate: '',
		InitialOwner: '',
		IsDemo: false,
		ErpId: rwo.erpId,
		ReadOnly: false,
		XgRedirect: false,
		Notes: rwo.notes,
		CommerciallyRelevant: rwo.commerciallyRelevant,
		BilledSubscription: rwo.billedSubscription,
		BilledModule: rwo.billedModule,
	};
};

/**
 * This service is in charge of workspace-related CRUD operations that concern the subscription service.
 */
export class SubscriptionWorkspaceService extends BaseService<Workspace> {
	private service: SubscriptionService;

	public constructor(endpoint: string) {
		super({}, {});
		const token = new BearerToken();

		let options: FaroSubscriptionOptionalParams | undefined;
		// Allow BearerToken authentication on non ssl connections (necessary on localhost).
		if (config.env === 'local') {
			options = {
				requestPolicyFactories: (defaultFacories) => {
					// Remove default BearerTokenPolicy because it blocks calling non ssl.
					const requestFactories = defaultFacories.filter((factory) => factory.create.toString().indexOf('bearerTokenPolicyFactory') < 0);

					// Add localBearerTokenAuthenticationPolicyFactory which allows calling non ssl on localhost.
					requestFactories.push(localBearerTokenAuthenticationPolicyFactory(token));
					return requestFactories;
				},
			};
		}
		this.service = new SubscriptionService(token, endpoint, options);
	}

	public async getSingle<QueryT extends object>(uuid: string, query?: QueryT): Promise<IWorkspace> {
		const rw = await this.service.getWorkspace(uuid);
		return workspaceFromRestType(rw);
	}

	public async getAll<QueryT extends object>(query?: QueryT): Promise<IWorkspace[]> {
		// SubSvc takes the user UUID from the token anyways. The UUID in the path is ignored; we just need a non-empty string.
		const userUuidUnused = '00000000-0000-0000-0000-000000000000';
		// If we still wanted to use the real user ID, this code would work:
		// const $tsStore: $tsStore = Vue.prototype.$tsStore;
		// await $tsStore.users.getTokenSilently();
		// const decodedToken = $tsStore.users.decodedToken;
		// const userUuidUnused = decodedToken['https://farosphere.com/userid'] || '0';

		const workspaces = await this.service.getWorkspacesByUser(userUuidUnused);

		// filter the null workspaces before returning,
		// as subscription backend could return null workspace item and if not filtered could throw
		// an error of whole mapping for single null value on the workspace array.
		return workspaces
			.filter((rw) => rw !== null)
			.map((rw) => workspaceFromRestType(rw));
	}

	/**
	 * Returns all workspaces overviews, including those the requesting user is not a member of.
	 * Since the request is slow, we cache them in localStorage.
	 * Example for DEV, from Korntal office:
	 * - from cache: 0.06 sec
	 * - from API:   1.85 sec
	 * Since most browsers allow max. 5 MB of localStorage per host (https://arty.name/localstorage.html),
	 * we must compress the data, at least for PROD.
	 * @author OK, MH
	 */
	public async getAllWorkspaceOverviews(): Promise<IWorkspace[]> {
		// On localhost, don't mix up workspaces from localhost with those from DEV ("npm run start-aws-dev").
		const cacheKey = 'workspaceOverviews_' + config.env;

		let fromCache = false;
		let workspacesRaw: WorkspaceOverview[] | undefined;
		try {
			let workspacesCachedStr = localStorage.getItem(cacheKey);
			if (workspacesCachedStr) {
				workspacesCachedStr = decompressFromBase64(workspacesCachedStr);
				const workspacesCached = JSON.parse(workspacesCachedStr);
				const ageMinutes = (Date.now() - workspacesCached.date) / 60000;
				if (ageMinutes < 5 && Array.isArray(workspacesCached.workspacesRaw)) {
					console.log('Using cached workspaceOverviews; age =', ageMinutes.toFixed(1), 'minutes (max = 5 min)');
					workspacesRaw = workspacesCached.workspacesRaw;
					fromCache = true;
				}
			}
		} catch (e) {
			console.error('Error reading cached workspaceOverviews', e);
		}

		if (!workspacesRaw) {
			workspacesRaw = await this.service.getWorkspacesOverviews();
			// Avoid storing PII and other unnecessary data that we don't use in localStorage.
			// This also reduces our localStorage usage.
			for (const w of workspacesRaw) {
				w.customerCompany = '';
				w.customerId = '';
				w.customerPreSignUpEmail = '';
				w.customerSAPNumber = '';
				w.faroContacts = [];
				w.notes = '';
				w.ownerEmail = '';
				w.ownerId = '';
			}
		}
		const workspaces = workspacesRaw
			.filter((rwo) => rwo !== null)
			.map((rwo) => workspaceFromOverviewRestType(rwo));

		if (!fromCache) {
			try {
				const workspacesCachedStr = compressToBase64(JSON.stringify({ date: Date.now(), workspacesRaw }));
				console.log('Caching workspaceOverviews:', (workspacesCachedStr.length / 1024 / 1024).toFixed(1),
					'MB (max = 5 MB per host for most browsers)');
				localStorage.setItem(cacheKey, workspacesCachedStr);
			} catch (e) {
				console.error('Error storing cached workspaceOverviews', e);
			}
		}
		return workspaces;
	}

	public async getWorkspacesFeatures(): Promise<WorkspaceFeatures[]> {
		const customHeaders = await BaseServiceAny.getCustomHeaders();
		const client = new HttpClient();
		const url = `${config.subscriptionApiEndpoint}/subscription/v1/workspaces/features`;
		// throws HttpError
		const features = (await client.get(url, { customHeaders })) as WorkspaceFeatures[];
		return features;
	}

	/**
	 * Deactivates the workspace in the Subscription Service.
	 * @author OK
	 * @param workspaceUuid Workspace UUID.
	 */
	public async deactivateWorkspace(workspaceUuid: string): Promise<void> {
		await this.service.deactivateWorkspace(workspaceUuid);
	}

	public async create<QueryT extends object>(w?: Workspace, query?: QueryT): Promise<IWorkspace> {
		const params: FaroSubscriptionCreateWorkspaceOptionalParams = {
			body: {
				name: w?.Name ?? '',
				description: w?.Description ?? '',
				region: w?.Region ?? '', // Previous default value was 'eu'. Default should not be needed.
			},
		};
		const resp = await this.service.createWorkspace(params);
		return workspaceFromRestType(resp._response.parsedBody);
	}

	public async updateSingle<QueryT extends object>(w?: Workspace, query?: QueryT): Promise<IWorkspace> {
		const params: FaroSubscriptionUpdateWorkspaceOptionalParams = {
			body: {
				name: w?.Name,
				description: w?.Description,
				region: w?.Region,
			},
		};
		const resp = await this.service.updateWorkspace(w?.UUID!, params);
		return workspaceFromRestType(resp._response.parsedBody);
	}

	public async activateWorkspace<QueryT extends object>(w?: Workspace, query?: QueryT): Promise<IWorkspace> {
		const resp = await this.service.activateWorkspace(w?.UUID!);
		return workspaceFromRestType(resp._response.parsedBody);
	}

	public async updateMulti<QueryT extends object>(body?: Workspace[], query?: QueryT): Promise<IWorkspace[]> {
		throw new Error('Function not yet implemented');
	}

	public async remove<QueryT extends object>(uuid: string, query?: QueryT): Promise<{ Success: true }> {
		throw new Error('Function not yet implemented');
	}

	public async workspaceExists(name: string): Promise<boolean> {
		try {
			const resp = await this.service.workspaceExists(name);
			return resp._response.status === 200;
		} catch (e) {
			return false;
		}
	}
}
