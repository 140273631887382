import { BASE_API_URL, config } from '@/config';
import { IWorkspace, IWorkspaceSizes, IWorkspaceVisitString } from '@/definitions/interfaces';
import { ClientOptions } from '@/store/services/BaseService';
import { AuthzInterface } from '@faroconnect/authz-client';
import { BaseServiceAny } from './BaseServiceAny';

export class UtilsWorkspaceService extends BaseServiceAny {
	constructor(clientConfig: ClientOptions) {
		super({
			apiRoute: 'workspace',
			apiEndpoint: config.apiEndpoint,
			baseApiUrl: BASE_API_URL,
		}, clientConfig);
	}

	public async updateWorkspace(workspaceUuid: string, workspace: Partial<AuthzInterface.IWorkspace>): Promise<IWorkspace> {
		const url = this.makeUrl() + '/' + workspaceUuid + '/update';
		return await this.put(url, workspace);
	}

	public async getAllLastVisited(): Promise<IWorkspaceVisitString[]> {
		const url = this.makeUrl() + '/get-all-visited';
		return await this.get(url);
	}

	public async updateLastVisited(workspaceUuid: string): Promise<IWorkspaceVisitString> {
		const url = this.makeUrl() + '/' + workspaceUuid + '/update-visit-time';
		return await this.put(url);
	}

	public async suggestName(): Promise<{ Success: boolean, Message: string, Suggestion: string }> {
		const url = this.makeUrl() + '/suggest-name';
		return await this.get(url);
	}

	/**
	 * Gets workspace size information for all provided workspaces from WebShare.
	 * The backend route combines the results of both WebShare regions.
	 * Used by the migration statistics page.
	 * The call of the WebShare route was implemented in the backend since the WebShare route requires API key
	 * authorization which isn't set up for the frontend.
	 * @author OK
	 * @param workspaceUuids Array of workspace UUIDs for which size information shall be retrieved.
	 * @param date Used for the access to WebShare's storagebyresource table to get the project folder size.
	 */
	public async getWorkspaceSizes(workspaceUuids: string[], date: Date): Promise<IWorkspaceSizes> {
		const url = this.makeUrl() + `/workspacesizes/${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
		return await this.post(url, workspaceUuids);
	}
}
