import Vue from 'vue';
import { Action, Module, Mutation, RegisterOptions } from 'vuex-class-modules';
import { AuthzInterface, AuthzReq } from '@faroconnect/authz-client';
import { PageModule } from '@/store/modules/PageModule';
import { BaseFilterModule } from '@/store/modules/BaseFilterModule';
import { SubscriptionWorkspaceService } from '@/store/services/SubscriptionWorkspaceService';
import { Workspace, IWorkspace } from '@/classes/authz/Workspace';
import { InterfaceOf } from '@/classes';
import { config } from '@/config';
import { AuthzWorkspaceService, GetAllWorkspacesQuery, IInvitationResponse } from '@/store/services/authz/AuthzWorkspaceService';
import { UserService } from '@/store/services/UserService';
import { UtilsWorkspaceService } from '@/store/services/UtilsWorkspaceService';
import { $assert, ConflictError, UuidUtils } from '@faroconnect/utils';
import { faroComponents } from '@faroconnect/baseui';
import { IUserRespondToInvitationPayload, IWorkspaceSizes, IWorkspaceVisitString } from '@/definitions/interfaces';
import { WebshareService } from '@/store/services/WebshareService';
import { Project } from '@/classes/authz/Project';
import { WorkspaceLimits } from '@/definitions-frontend/types-client-only';
import { sortByLastVisit } from '@/utils/date';
import { demoWorkspace } from '@/definitions-frontend/demo-workspace';

/**
 * Interceptor for workspaces.
 * This will be called after a getAll request before creating the instances to be added to the store.
 * If attributes inside the entity should be added to another store, this function will take care of it.
 * @param workspaces The workspaces response from the server.
 * @param lastVisits [Optional] Prefetched data from $tsStore.workspaces.getAllLastVisited().
 */
async function workspacesInterceptor(
	workspaces: Array<InterfaceOf<Workspace>>,
	lastVisits?: IWorkspaceVisitString[],
): Promise<void> {
	// Fetch last visits if not provided.
	// This allows the caller to prefetch the last-visits data, making requests in parallel.
	if (!lastVisits) {
		try {
			const $tsStore: $tsStore = Vue.prototype.$tsStore;
			lastVisits = await $tsStore.workspaces.getAllLastVisited();
		} catch (error) {
			lastVisits = [];
			console.error(error);
		}
	}

	// Create a Map out of the lastVisits array so we can get the items with O(1) complexity.
	const lastVisitsMap: Map<string, IWorkspaceVisitString> = new Map();

	lastVisits.forEach((lastVisit) => lastVisitsMap.set(lastVisit.WorkspaceUuid, lastVisit));

	for (const workspace of workspaces) {
		const foundVisit = lastVisitsMap.get(workspace.UUID);

		workspace.LastVisitDate = foundVisit ? foundVisit.LastVisitDate : '';
	}
}

@Module
export class WorkspaceModule extends BaseFilterModule<Workspace> {
	// ###################################### Properties ######################################

	// ###### Public ######
	public workspaceLimits: { [key: string]: WorkspaceLimits | undefined } = {};
	/**
	 * The workspace displayed in the UI.
	 */
	public activeWorkspace: Workspace | null = null;

	// ###### Protected ######

	protected readonly service = new AuthzWorkspaceService({});
	protected readonly userService = new UserService({});
	protected readonly utilsWorkspaceService = new UtilsWorkspaceService({});
	protected readonly subscriptionService = new SubscriptionWorkspaceService(config.subscriptionApiEndpoint);
	protected readonly webshareService = new WebshareService();
	protected readonly WEBSHARE_DOMAIN_CREATION_CHECK_RETRY_LIMIT = 60;
	protected readonly WEBSHARE_DOMAIN_CREATION_CHECK_RETRY_DELAY_MS = 5000;

	// ###### Private ######


	// ###################################### Getters ######################################

	// ###### Public ######

	public get projectsForFilterList(): Project[] {
		// Since there are no projects in the workspaces page, just return an empty array.
		return [];
	}

	public get filterByProjectSourceOptions() {
		// Since there are no project sources in the workspaces page, just return an empty array.
		return [];
	}
	public get filterByProjectStatusOptions() {
		// Since there are no project statuses in the workspaces page, just return an empty array.
		return [];
	}

	/**
	 * Returns if the demo workspace is the active workspace displayed in the UI.
	 * @author OK
	 */
	public get isDemoActive(): boolean {
		return !!this.activeWorkspace?.IsDemo;
	}

	// ###### Protected ######

	// ###### Private ######

	// ###################################### Constructor ######################################

	constructor(protected pages: PageModule, options: RegisterOptions) {
		super(pages, options, Workspace);
	}

	// ###################################### Actions ######################################

	// ###### Public ######

	@Action
	public async getAll() {
		// Make all requests in parallel, to speed things up.
		const subscriptionWorkspacesPromise = this.subscriptionService.getAll().catch((e) => {
			// Treat subscription service as an optional component for now.
			console.error('Failed to read workspaces from SubSvc', e);
			return [] as IWorkspace[];
		});

		const authzWorkspacesPromise = this.service.getAll<GetAllWorkspacesQuery>({
			withinviters: true, withsharedate: true, omitdeactivated: true,
		});

		const lastVisitsPromise = Vue.prototype.$tsStore.workspaces.getAllLastVisited().catch((e: any) => {
			// Treat the last-visited dates as optional functionality.
			console.error('Failed to read last workspace visits', e);
			return [] as IWorkspaceVisitString[];
		});

		let [subscriptionWorkspaces, authzWorkspaces, lastVisits] =
			await Promise.all([subscriptionWorkspacesPromise, authzWorkspacesPromise, lastVisitsPromise]);

		// We need to omit the deactivated and deleted workspaces.
		subscriptionWorkspaces = subscriptionWorkspaces.filter((w) => (w.State !== 'deactivated' && w.State !== 'deleted'));

		const items = [
			...authzWorkspaces.map((workspace) => Workspace.fromResponse(workspace)),
			...subscriptionWorkspaces.map((workspace) => Workspace.fromResponse(workspace)),
		];

		await workspacesInterceptor(items, lastVisits);

		this.addItems(items);
	}

	@Action
	public async getSingle(uuid: string): Promise<void> {
		this.addItem(Workspace.fromResponse(await this.subscriptionService.getSingle(uuid)));
	}

	@Action
	public async updateSingle<QueryT extends object>(w: Workspace, query?: QueryT): Promise<void> {
		const updatedWorkspace = await this.utilsWorkspaceService.updateWorkspace(w.UUID, w as AuthzInterface.IWorkspace);
		this.updateWorkspace({ uuid: w.UUID, newWorkspace: updatedWorkspace as Partial<IWorkspace> });
	}

	/**
	 * Alternative version of updateSingle that updates a workspace's attributes:
	 *  - Attributes to update are given separately.
	 *  - Shows error box if an error happens.
	 * @author OK
	 * @param uuid Workspace UUID.
	 * @param data Changed attributes to update.
	 * @throws {HttpError}
	 */
	@Action
	public async updateSingle2({uuid, data}: {uuid: string, data: Partial<AuthzInterface.IWorkspace>}): Promise<void> {
		try {
			// throws HttpError
			const updatedWorkspace = await this.utilsWorkspaceService.updateWorkspace(uuid, data);
			this.updateWorkspace({ uuid, newWorkspace: updatedWorkspace as Partial<IWorkspace> });
		} catch (error) {
			faroComponents.$emit('show-error', { error, message: 'LP_ERR_UPDATE_WORKSPACE' });
			throw error;
		}
	}

	@Action
	public async completeWorkspace<QueryT extends object>(w: Workspace, query?: QueryT) {
		// Send updated to Subscriptionservice. This is most important for pending workspaces to become active.
		// In case of pending workspace the subscription service will create the workspace in authz.
		const result = await this.subscriptionService.updateSingle(w);
		const active = await this.subscriptionService.activateWorkspace(w);
		if (result.UUID !== active.UUID || active.State !== 'active') {
			throw new ConflictError('Workspace could not be activated.');
		}
		this.updateWorkspace({ uuid: w.UUID, newWorkspace: active });
		try {
			await this.initializeCompletedWorkspace(w.UUID);
		} catch (error) {
			console.error(error);
			faroComponents.$emit('show-error', { error, message: 'LP_ERR_SHOW_WORKSPACE' });
			// Early exit because it makes no sense to get the workspace permissions if it already failed to get the workspace.
			return;
		}
		try {
			const $tsStore: $tsStore = Vue.prototype.$tsStore;
			await $tsStore.users.getWorkspacePermissions(w.UUID);
		} catch (error) {
			console.error(error);
			faroComponents.$emit('show-error', { error, message: 'LP_ERR_GET_WORKSPACE_PERMISSIONS' });
		}
	}

	@Action
	public async inviteUsers(payload: { workspaceUuid: string, data: AuthzReq.InviteUsersReq }) {
		return this.service.inviteUsers(payload.workspaceUuid, payload.data);
	}

	@Action
	public async activateUser(workspaceUuid: string): Promise<void> {
		await this.service.activateUser(workspaceUuid);
		this.setWorkspaceUserStateActive(workspaceUuid);
	}

	@Action
	public async unassignCallingUser(payload: { workspaceUuid: string, action?: 'reject' | 'leave'}): Promise<void> {
		if (payload.action === undefined) {
			payload.action = 'reject';
		}
		await this.service.unassignCallingUser(payload.workspaceUuid, payload.action);
		this.removeItem(payload.workspaceUuid);
		// throws HttpError, Error
		await this.initializeActiveWorkspace({});
		// Code line that would be used for the approach to set the active workspace to null,
		// instead of opening the next best workspace.
		// throws HttpError
		// await this.updateActiveWorkspaceWithSettings(null);
	}

	/**
	 * The user clicked on the invitation link in the received email and then
	 * a token and action will be sent to the server. This is done to validate the token
	 * and then update AssocWorkspaceUsers table (user state).
	 */
	@Action
	public async userRespondToInvitation(payload: IUserRespondToInvitationPayload): Promise<IInvitationResponse> {
		const response = await this.service.userRespondToInvitation(payload);
		if (payload.action === 'accept') {
			this.setWorkspaceUserStateActive(response.Workspace.UUID);
		} else {
			this.removeItem(response.Workspace.UUID);
			// User will then be redirected by a hard browser reload by the calling method in InviteResultPage.
			// So we don't have to care about the active workspace or workspace selection as in unassignCallingUser.
		}
		return response;
	}

	@Action
	public async create<QueryT extends object>(w?: Workspace, query?: QueryT): Promise<Workspace> {
		const workspace = Workspace.fromResponse(await this.subscriptionService.create(w));
		workspace.DomainCreationStatus = 'pending'; // Set status to pending for initial workspace creation.
		this.addItem(workspace);

		try {
			if (!workspace.UUID) {
				throw new Error('missing UUID in workspace');
			}
			const authzWorkspace = await this.service.getSingle(workspace.UUID);
			if (authzWorkspace.UUID !== workspace.UUID) {
				throw new Error(`The UUIDs do not match. Found in subscription ${workspace.UUID} and in authz ${authzWorkspace.UUID}.`);
			}
			this.addOrMergeItem(Workspace.fromResponse(authzWorkspace));
			this.watchWorkspaceCreation(workspace);
		} catch (error) {
			console.error(error);
			faroComponents.$emit('show-error', { error, message: 'LP_ERR_SHOW_WORKSPACE' });
			// Early exit because it makes no sense to get the workspace permissions if it already failed to get the workspace.
			return workspace;
		}

		try {
			const $tsStore: $tsStore = Vue.prototype.$tsStore;
			await $tsStore.users.getWorkspacePermissions(workspace.UUID);
		} catch (error) {
			console.error(error);
			faroComponents.$emit('show-error', { error, message: 'LP_ERR_GET_WORKSPACE_PERMISSIONS' });
		}

		return workspace;
	}

	@Action
	public async getAllLastVisited(): Promise<IWorkspaceVisitString[]> {
		const lastVisits: IWorkspaceVisitString[] = await this.utilsWorkspaceService.getAllLastVisited();
		lastVisits.forEach(this.setLastVisitDate);
		return lastVisits;
	}

	@Action
	public async updateLastVisited(workspaceUuid: string) {
		const lastVisit = await this.utilsWorkspaceService.updateLastVisited(workspaceUuid);
		this.setLastVisitDate(lastVisit);
	}

	/**
	 * Check the workspace from the webshare. If the subdomain creation job has not been
	 * completed set the DomainCreationStatus property to pending.
	 */
	@Action
	public async checkDomainCreationStatusForAllWorkspaces(): Promise<void> {
		this.itemsList.map(async (workspace) => {
			if (workspace.isOlderThanOneDay()) {
				return workspace;
			}
			if (await this.isWebshareSubdomainCreated(workspace)) {
				const newWorkspace = {...workspace, DomainCreationStatus: 'created' };
				this.updateWorkspace({uuid: workspace.UUID, newWorkspace});
			} else {
				const newWorkspace = {...workspace, DomainCreationStatus: 'pending' };
				this.watchWorkspaceCreation(workspace);
				this.updateWorkspace({uuid: workspace.UUID, newWorkspace});
			}
		});
	}

	/**
	 * Adds the FARO demo workspace to the items map.
	 * @author OK
	 */
	@Action
	public setDemoWorkspace(): void {
		const demo = Workspace.forRequest(demoWorkspace.workspace);
		this.addItem(demo);
	}

	/**
	 * Sets the provided workspace as active workspace, also updating the user settings on the server.
	 * @author OK
	 * @param workspace Workspace to serve as active workspace.
	 * @throws {HttpError}
	 */
	@Action
	public async updateActiveWorkspaceWithSettings(workspace: Workspace | null): Promise<void> {
		const $tsStore: $tsStore = Vue.prototype.$tsStore;
		this.setActiveWorkspace(workspace);
		if (workspace?.IsDemo) {
			$tsStore.projects.setDemoProject();
		}
		// throws HttpError
		await $tsStore.settings.updateActiveWorkspace(workspace?.UUID || null);
	}

	/**
	 * Sets the provided workspace as active workspace, without updating the user settings.
	 * @author OK
	 * @param workspace Workspace to serve as active workspace.
	 */
	@Action
	public async updateActiveWorkspaceWithoutSettings(workspace: Workspace | null): Promise<void> {
		const $tsStore: $tsStore = Vue.prototype.$tsStore;
		this.setActiveWorkspace(workspace);
		if (workspace?.IsDemo) {
			$tsStore.projects.setDemoProject();
		}
	}

	/**
	 * Sets the active workspace on page load and also updates the user setting accordingly if necessary.
	 * @author OK
	 * @param uuidFromRoute If the route contains a workspace UUID, we reset the active workspace to it.
	 * @param $router Since Vue.prototype.$router doesn't work, it's easiest to let the component provide it.
	 *        Only relevant when uuidFromRoute is provided.
	 * @throws {HttpError}
	 * @throws {Error}
	 */
	@Action
	public async initializeActiveWorkspace(payload: { uuidFromRoute?: string, $router?: any }): Promise<void> {
		const uuidFromRoute = payload.uuidFromRoute;
		const $router = payload.$router;
		const $tsStore: $tsStore = Vue.prototype.$tsStore;

		if (uuidFromRoute && !UuidUtils.isSimpleUuid(uuidFromRoute)) {
			// 1. Show an error alert box.
			// 2. Set the active workspace to the default one.
			// 3. Show the workspace selection page, including the default one to not confuse the user.
			faroComponents.$emit('show-error', { title: 'LP_ERR_GET_WORKSPACE_TITLE', message: 'LP_ERR_INVALID_ID' });
			// throws HttpError, Error
			await this.initializeActiveWorkspace({});
			if ($router) {
				$router.push({ name: 'SelectWorkspacePage', params: { includeActive: 'true' } });
			}
			return;
		}

		// If there's no workspace, we need to set the active workspace to null.
		// This is an unexpected error since the demo workspace was added to the items map in setDemoWorkspace before.
		if (!this.itemsList.length) {
			// throws HttpError
			await this.updateActiveWorkspaceWithSettings(null);
			// Don't set an error message so that "An unknown error occurred." is displayed.
			throw new Error();
		}

		const uuidActive = $tsStore.settings.activeWorkspace;

		// If there's a workspace UUID provided in the URL of the first page load, we set it as active workspace.
		// Otherwise, deep links to e.g. the project info page of a currently not active workspace wouldn't work.
		if (uuidFromRoute && uuidFromRoute !== uuidActive) {
			const workspace = this.ItemsMap[uuidFromRoute];
			if (!workspace) {
				// If the workspace UUID from the route is unknown, e.g. because the user doesn't have access to the
				// workspace, we carry out similar actions as when an invalid UUID was provided.
				faroComponents.$emit('show-error', { title: 'LP_ERR_GET_WORKSPACE_TITLE', message: 'LP_ERR_WORKSPACE_ACCESS' });
				// throws HttpError, Error
				await this.initializeActiveWorkspace({});
				if ($router) {
					$router.push({ name: 'SelectWorkspacePage', params: { includeActive: 'true' } });
				}
			} else if (workspace.isPendingUserInvitation) {
				// If the user hasn't accepted the workspace invitation yet, we carry out similar actions as when an
				// invalid UUID was provided.
				faroComponents.$emit('show-error', { title: 'LP_ERR_GET_WORKSPACE_TITLE', message: 'LP_ERR_NOT_ACCEPTED' });
				// throws HttpError, Error
				await this.initializeActiveWorkspace({});
				if ($router) {
					$router.push({ name: 'SelectWorkspacePage', params: { includeActive: 'true' } });
				}
			} else {
				// throws HttpError
				await this.updateActiveWorkspaceWithSettings(workspace);
			}
			return;
		}

		// STANDARD CASE on all subsequent page loads:
		// If an active workspace is set in the user settings, we use it as long as the workspace exists.
		for (const workspace of this.itemsList) {
			if (workspace.UUID === uuidActive) {
				await this.updateActiveWorkspaceWithoutSettings(workspace);
				return;
			}
		}

		// No active workspace is set in the user settings, so we determine a good choice and then set it.
		const workspaces: Workspace[] = [];
		for (const workspace of this.itemsList) {
			if (workspace.LastVisitDate && !workspace.isPendingUserInvitation) {
				workspaces.push(workspace);
			}
		}

		// STANDARD CASE on first page load after feature deployment:
		// Set the workspace with the most recent visit date as the active one.
		if (workspaces.length) {
			sortByLastVisit(workspaces);
			const workspace = workspaces[0];
			// throws HttpError
			await this.updateActiveWorkspaceWithSettings(workspace);
			return;
		}

		// If there's no workspace with a visit date, we take the first normal one in the list.
		for (const workspace of this.itemsList) {
			if (!workspace.IsDemo && !workspace.isPendingUserInvitation) {
				// throws HttpError
				await this.updateActiveWorkspaceWithSettings(workspace);
				return;
			}
		}

		// If there's no normal workspace, we take the demo workspace as last fallback.
		for (const workspace of this.itemsList) {
			$assert.Assert(workspace.IsDemo);
			// throws HttpError
			await this.updateActiveWorkspaceWithSettings(workspace);
			return;
		}

		// Don't set an error message so that "An unknown error occurred." is displayed.
		throw new Error();
	}

	/**
	 * Check a single workspace subdomain creation status. If the subdomain creation job has not been
	 * completed set the DomainCreationStatus property to pending.
	 */
	@Action
	public async checkDomainCreationStatusForAWorkspace(workspace: Workspace): Promise<void> {
		if (workspace.isOlderThanOneDay()) {
			return;
		}
		if (await this.isWebshareSubdomainCreated(workspace)) {
			const newWorkspace = {...workspace, DomainCreationStatus: 'created' };
			this.updateWorkspace({uuid: workspace.UUID, newWorkspace});
		} else {
			const newWorkspace = {...workspace, DomainCreationStatus: 'pending' };
			this.watchWorkspaceCreation(workspace);
			this.updateWorkspace({uuid: workspace.UUID, newWorkspace});
		}
	}

	@Action
	public async getWorkspaceLimits(workspaceUuid: string): Promise<WorkspaceLimits> {
		const authzClient = await this.getAuthzClient();
		const workspaceLimits = await authzClient.workspace.readWorkspaceUserLimits(workspaceUuid);
		this.setWorkspaceLimits({ workspaceUuid, workspaceLimits });
		return workspaceLimits;
	}

	/**
	 * Deactivates the workspace in the Subscription Service.
	 * @author OK
	 * @param workspaceUuid Workspace UUID.
	 */
	@Action
	public async deactivateWorkspace(workspaceUuid: string): Promise<void> {
		await this.subscriptionService.deactivateWorkspace(workspaceUuid);
		this.removeItem(workspaceUuid);
		// throws HttpError, Error
		await this.initializeActiveWorkspace({});
	}

	/**
	 * Gets the active workspace from the server again to get its current properties, in particular its ReadOnly and
	 * XgRedirect flags.
	 * @author OK
	 */
	@Action
	public async retrieveActiveWorkspace(): Promise<void> {
		if (!this.activeWorkspace) {
			return;
		}

		const updatedWorkspace = await this.service.getSingle(this.activeWorkspace.UUID);
		// Even if the workspace should no longer be the active one since another one was selected during the request
		// duration, we can safely update it.
		this.updateWorkspace({ uuid: updatedWorkspace.UUID, newWorkspace: updatedWorkspace });
		// Activate/Deactivate all UI elements based on the read-only state if it's still the active workspace.
		if (this.activeWorkspace.UUID === updatedWorkspace.UUID) {
			const $tsStore: $tsStore = Vue.prototype.$tsStore;
			await $tsStore.users.getWorkspacePermissions(updatedWorkspace.UUID);
		}
	}

	// ###### Protected ######

	// ###### Private ######

	// ###################################### Mutations ######################################

	// ###### Public ######

	@Mutation
	public setWorkspaceLimits(payload: { workspaceUuid: string, workspaceLimits: WorkspaceLimits }) {
		const val: WorkspaceModule['workspaceLimits'][typeof payload.workspaceUuid] = payload.workspaceLimits;
		Vue.set(this.workspaceLimits, payload.workspaceUuid, val);
	}

	@Mutation
	public setLastVisitDate(lastVisit: IWorkspaceVisitString) {
		const uuid = lastVisit.WorkspaceUuid;
		if (this.ItemsMap[uuid]) {
			this.ItemsMap[uuid].LastVisitDate = lastVisit.LastVisitDate;
		}
	}

	@Mutation
	public addOrMergeItem(workspace: Workspace) {
		const oldValue = this.ItemsMap[workspace.UUID];
		if (oldValue) {
			oldValue.merge(workspace);
		} else {
			Vue.set(this.ItemsMap, workspace.UUID, workspace);
		}
	}

	@Mutation
	public addItems(items: Workspace[]) {
		items.forEach((item: Workspace) => {
			// Pending workspaces only exist in subscriptionservice - no merge required
			if (item.State === 'pending') {
				Vue.set(this.ItemsMap, item.UUID, item);
				return;
			}

			const oldValue = this.ItemsMap[item.UUID];

			// If new Workspace no merge required
			if (oldValue === undefined) {
				Vue.set(this.ItemsMap, item.UUID, item);
				return;
			}

			item.merge(oldValue);
			Vue.set(this.ItemsMap, item.UUID, item);
		});
	}

	@Mutation
	public setWorkspaceUserStateActive(workspaceUuid: string) {
		const key: keyof Workspace = 'WorkspaceUserState';
		const value: Workspace[typeof key] = 'active';
		if (this.ItemsMap[workspaceUuid]) {
			Vue.set(this.ItemsMap[workspaceUuid], key, value);
		}
	}

	@Mutation
	public removeItem(workspaceUuid: string) {
		Vue.delete(this.ItemsMap, workspaceUuid);
	}

	/**
	 * Replace and update workspace properties with new data;
	 * @param uuid The uuid of the workspace that should be updated.
	 * @param newWorkspace Is the new Workspace data.
	 */
	@Mutation
	public updateWorkspace({ uuid, newWorkspace }: { uuid: string, newWorkspace: Partial<IWorkspace>}): void {
		const workspace: Workspace = this.ItemsMap[uuid];
		if (workspace) {
			this.ItemsMap[uuid].updateProperties(newWorkspace);
		} else {
			Vue.set(this.ItemsMap, uuid, newWorkspace);
		}
	}

	@Mutation
	public setActiveWorkspace(activeWorkspace: Workspace | null): void {
		const prevWorkspace = this.activeWorkspace;
		this.activeWorkspace = activeWorkspace;

		// Avoid this edge case, related to the logic in PageBaseMixin.xInitializeAfterFirstPage():
		// - Open /home/projects/WORKSPACE, with pending invitation to WORKSPACE.
		// - LP redirects to workspace selection, and sets the active workspace to a different one, WORKSPACE2.
		// - Accept invitation for WORKSPACE.
		// - Click on WORKSPACE, which again opens /home/projects/WORKSPACE.
		// - Without this fix: Projects of WORKSPACE2 are shown.
		// - With this fix:    Projects of WORKSPACE are shown.
		if (activeWorkspace && prevWorkspace && activeWorkspace.UUID !== prevWorkspace.UUID && this.pages.firstOpenedPage) {
			this.pages.setFirstOpenedPage(null);
		}
	}

	// ###### Protected ######

	// ###### Private ######

	// ###################################### Helper Methods ######################################

	// ###### Public ######

	public isDomainOwner(payload: { userUuid: string, workspaceUuid: string }): boolean {
		const workspace = this.ItemsMap[payload.workspaceUuid];
		if (!workspace) {
			return false;
		}
		return workspace.Owner === payload.userUuid;
	}

	public async workspaceExists(name: string): Promise<boolean> {
		return this.subscriptionService.workspaceExists(name);
	}

	public async suggestName(): Promise<{ Success: boolean, Message: string, Suggestion: string }> {
		return this.utilsWorkspaceService.suggestName();
	}

	public async getWorkspaceFromUuid(uuid: string): Promise<Workspace> {
		return Workspace.fromResponse(await this.subscriptionService.getSingle(uuid));
	}

	public async updateOwner(workspaceUUID: string, userUUID: string): Promise<void> {
		const owner = await this.service.updateOwner(workspaceUUID, userUUID);
		const workspace = this.ItemsMap[workspaceUUID];
		const newWorkspace = {...workspace, Owner: userUUID };
		this.updateWorkspace({uuid: workspace.UUID, newWorkspace});
		return owner;
	}

	/**
	 * Gets workspace size information for all provided workspaces from WebShare.
	 * The backend route combines the results of both WebShare regions.
	 * Used by the migration statistics page.
	 * The call of the WebShare route was implemented in the backend since the WebShare route requires API key
	 * authorization which isn't set up for the frontend.
	 * @author OK
	 * @param workspaceUuids Array of workspace UUIDs for which size information shall be retrieved.
	 * @param date Used for the access to WebShare's storagebyresource table to get the project folder size.
	 */
	public async getWorkspaceSizes(payload: { workspaceUuids: string[], date: Date }): Promise<IWorkspaceSizes> {
		return await this.utilsWorkspaceService.getWorkspaceSizes(payload.workspaceUuids, payload.date);
	}

	// ###### Protected ######

	/**
	 * Filters a list of workspaces by keeping only the ones that have some attribute,
	 * that matches some search text.
	 * @param workspaces The original workspace list.
	 * @param searchTxt The search text.
	 * @returns A new filtered workspace list.
	 */
	protected filterByTextItems(workspaces: Workspace[], searchTxt: string): Workspace[] {
		searchTxt = searchTxt.toLowerCase();
		return workspaces.filter((workspace) =>
			workspace.Name.toLowerCase().includes(searchTxt) ||
			workspace.Description?.toLowerCase().includes(searchTxt),
		);
	}

	/**
	 * Watch webshare to check if the subdomain has been created.
	 * @author Arden Ercelik
	 * @param Workspace.
	 */
	protected watchWorkspaceCreation(workspace: Workspace) {
		let count = 0;
		const interval = setInterval(async () => {
			try {
				if ((await this.isWebshareSubdomainCreated(workspace))) {
					const newWorkspace = {...workspace, DomainCreationStatus: 'created' };
					this.updateWorkspace({uuid: workspace.UUID, newWorkspace});
					clearInterval(interval);
					return;
				}
				if (count === this.WEBSHARE_DOMAIN_CREATION_CHECK_RETRY_LIMIT) {
					const newWorkspace = {...workspace, DomainCreationStatus: 'error' };
					this.updateWorkspace({uuid: workspace.UUID, newWorkspace});
					clearInterval(interval);
					faroComponents.$emit('show-error', { title: 'LP_SERVER_ERR', message: 'LP_ERR_CREATE_WORKSPACE' });
					return;
				}
				count++;
			} catch (error) {
				clearInterval(interval);
				throw Error(`Could not get webshare domain information. URL: ${workspace.webShareUrl}. Error: ${error}`);
			}
		}, this.WEBSHARE_DOMAIN_CREATION_CHECK_RETRY_DELAY_MS);
	}

	/**
	 * Makes a request to the webshare endpoint <subdomainname>.<websharedomain>/domain/
	 * to check if the webshare subdomain and workspace has been created.
	 * @author Arden Ercelik
	 * @param Workspace.
	 */
	protected async isWebshareSubdomainCreated(workspace: Workspace): Promise<boolean> {
		const webshareSubdomain = await this.webshareService.getDomain(workspace);
		// The correct attribute to check is WebshareDomainName. Just in case that it's empty, also allow Name.
		// Checking for workspace.WebshareAlias is not required.
		if (webshareSubdomain.Domain === workspace.WebshareDomainName || webshareSubdomain.Domain === workspace.Name) {
			return true;
		}
		return false;
	}

	/**
	 * Initialize a recently completed workspace in order to show it in the workspaces page.
	 * @param uuid Workspace UUID.
	 */
	protected async initializeCompletedWorkspace(uuid: string) {
		const subscriptionWorkspace = await this.subscriptionService.getSingle(uuid);
		const authzWorkspace = await this.service.getSingle(uuid);
		$assert.Assert(uuid === subscriptionWorkspace.UUID && uuid === authzWorkspace.UUID, 'UUIDs must be the same');
		const items = [
			Workspace.fromResponse(subscriptionWorkspace),
			Workspace.fromResponse(authzWorkspace),
		];
		await workspacesInterceptor(items);
		this.addItems(items);
		const workspace: Workspace = this.ItemsMap[uuid];
		await this.checkDomainCreationStatusForAWorkspace(workspace);
	}

	// ###### Private ######
}
