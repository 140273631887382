
import Vue, { VueConstructor } from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import SearchBar from '@/components/AppBar/SearchBar.vue';
import LeftNavigationDrawer from '@/components/MainPage/LeftNavigationDrawer.vue';
import { ErrorInfo, FaroSimpleIconButton, LanguageCode } from '@faroconnect/baseui';
import WelcomePage from '@/pages/WelcomePage.vue';
import { validateStringIfDefined } from '@/utils/validate';
import UpdateOrCreateWorkspaceTask from '@/components/Tasks/UpdateOrCreateWorkspaceTask.vue';
import { InitData } from '@/components/Tasks/UpdateOrCreateWorkspaceTask';
import UpdateOrCreateWorkspaceTaskSelect, { SelectWorkspaceInitData } from '@/components/Tasks/UpdateOrCreateWorkspaceTaskSelect.vue';
import { Workspace } from '@/classes/authz/Workspace';
import LpDialog from '@/components/Dialog/LpDialog.vue';
import { getErrorMessage } from './utils/errorhandler';
import { PAGE_LAYOUT_ENUM } from '@/definitions/constants';
import { PageLayout } from '@/definitions/types';
import { EntitiesFetchResult } from '@/utils/types';
import DeleteProjectConfirmation from '@/components/EntitiesPage/DeleteProjectConfirmation.vue';
import LegalNoticesTitle from '@/components/AppBar/LegalNoticesTitle.vue';
import { $assert, StringUtils } from '@faroconnect/utils';
import { config } from '@/config';
import { HttpError } from '@faroconnect/clientbase';
import { Generated } from '@faroconnect/authz-client';
import { BaseFilterModule } from './store/modules/BaseFilterModule';
import { getSearchBarItems, SearchItem } from './components/AppBar/SearchBar';
import { RouteName } from './router/routes';
import { getXgRedirectUrl, redirectToXg } from './utils/browser';
import { isFeatureEnabledByAuth0Token } from './utils/permissions';

const version: { version: string, commit: string } = require('@/../version').default;

@Component({
	components: {
		LeftNavigationDrawer,
		WelcomePage,
		DeleteProjectConfirmation,
		LpDialog,
	},
})
export default class App extends Vue {
	public version = version;
	public initialized = false;
	public criticalError = false;
	public errorTitle: string | null = null;
	public errorMsg: string | null = null;
	public showDrawer: boolean = false;
	public showSphereXgInfoDialog: boolean = false;
	public showWorkspaceCreationTask: boolean = false;
	public pageLayout: PageLayout = PAGE_LAYOUT_ENUM.thumbnail;
	public projectsFetchResult: EntitiesFetchResult = {
		errorMsg: null,
		loading: false,
	};
	public workspacesFetchResult: EntitiesFetchResult = {
		errorMsg: null,
		loading: false,
	};
	// The 'Feedback' button should not be shown in Production.
	public hideFeedbackBtn: boolean = config.env === 'prd';
	// Array of user pending workspace orders that need to be completed.
	public pendingWorkspaceOrders: Generated.SubSvcPendingWorkspaces = [];
	/**
	 * Error info objects to be displayed in the error box. To add a new one outside of this component, call:
	 * this.$faroComponents.$emit('show-error', { error, title, message });
	 */
	public errors: ErrorInfo[] = [];
	/**
	 * Flag if the user has closed the read-only message.
	 */
	public hasClosedReadOnlyMsg: boolean = false;
	/**
	 * Flag if the scheduled note should be visible.
	 */
	public isScheduledNoteShown: boolean = false;

	// ######################## Getters ########################

	public get isReadOnlyNoteShown(): boolean {
		// By referencing the route, make sure that the computed property updates on navigation.
		void this.$route.fullPath;
		return !this.hasClosedReadOnlyMsg && !!this.$tsStore.workspaces.activeWorkspace?.ReadOnly;
	}

	/**
	 * Get the active workspace UUID from the store.
	 */
	public get activeWorkspaceUuid(): string|null {
		const activeWorkspace: Workspace | null = this.$tsStore.workspaces.activeWorkspace;
		return activeWorkspace?.UUID ?? null;
	}

	/**
	 * Get the Webshare URL from config depending on the selected workspace's region.
	 */
	public get webshareUrl() {
		const activeWorkspace: Workspace | null = this.$tsStore.workspaces.activeWorkspace;
		const region = activeWorkspace?.Region || undefined;
		return region ? config.migratorApiEndpoints[region] || '' : '';
	}

	public get xgRedirectUrl(): string|null {
		const activeWorkspace: Workspace | null = this.$tsStore.workspaces.activeWorkspace;
		const xgRedirect = activeWorkspace?.XgRedirect;
		// Using this.$route.fullPath (or .path, without query string) is required get xgRedirectUrl updated on navigation.
		// We use a fallback route to get a meaningful URL e.g. for the Applications or Migration-related pages.
		if (xgRedirect && this.$route.fullPath.includes(activeWorkspace.UUID) && !this.$route.fullPath.includes('/migration')) {
			return getXgRedirectUrl(activeWorkspace.Region, this.$route.fullPath);
		} else if (xgRedirect) {
			return getXgRedirectUrl(activeWorkspace.Region, `/projects/${activeWorkspace.UUID}`);
		}
		return null;
	}

	public get workspaceCreationTaskVisible() {
		return this.showWorkspaceCreationTask;
	}

	public set workspaceCreationTaskVisible(value: boolean) {
		this.showWorkspaceCreationTask = value;
	}

	public get drawer() {
		return this.showDrawer;
	}

	public set drawer(value: boolean) {
		this.showDrawer = value;
	}

	public get searchBar(): VueConstructor<Vue> {
		return SearchBar;
	}

	public get loading(): boolean {
		return this.$auth?.loading ?? true;
	}

	public get leftButtons(): FaroSimpleIconButton[] {
		return [
			{
				icon: '$vuetify.icons.36_generic-menu',
				click: () => {this.drawer = !this.drawer;},
				hide: this.$vuetify.breakpoint.mdAndUp,
			},
		];
	}

	public get middleComponents(): Array<VueConstructor<Vue>> {
		return [];
	}

	public get middleComponentsLegalNotices(): Array<VueConstructor<Vue>> {
		return [LegalNoticesTitle];
	}

	public get searchBarItems(): SearchItem {
		return getSearchBarItems(this.$route, this.$tsStore);
	}

	public get appBarRightComponents(): VueConstructor<Vue>[] {
		const components: VueConstructor<Vue>[] = [];

		if (this.$vuetify.breakpoint.mdAndUp) {
			components.push(SearchBar);
		}

		const buttons = this.$vuetify.breakpoint.mdAndUp ?
			this.$tsStore.pages.appBarRightButtons : this.$tsStore.pages.appBarRightButtonsMobile;
		return components.concat(buttons.filter(({visible}) => visible).map(({component}) => component));
	}

	public get extendedComponent(): VueConstructor<Vue> | null {
		if (!this.criticalError && this.$vuetify.breakpoint.smAndDown && this.$tsStore.pages.searchBarMobileVisible && this.searchBarItems.show) {
			// We can't let the search bar component to decide its visibility in the extended component
			// because it is hidden the space for the extended component would be left unused.
			return this.searchBar;
		}

		return null;
	}

	public get isRouteDefined(): boolean {
		return !!this.$route.name;
	}

	public get isFullscreenTaskPage(): boolean {
		const routeName = this.$route.name as RouteName;
		switch (routeName) {
			case null:
			case 'SignupTask':
			case 'PendingEmailConfirmationPage':
			case 'PendingValidationPage':
			case 'FailedValidationPage':
			case 'InviteResultPage':
			case 'ChangeEmailPage':
			case 'LoginFailedPage':
			case 'NotFoundPage':
				return true;
			default:
				return false;
		}
	}

	public get hasPagePadding(): boolean {
		const routeName = this.$route.name as RouteName;
		switch (routeName) {
			case 'WorkspaceDashboardPage':
				return false;
			default:
				return true;
		}
	}

	public get isLegalNoticesPage(): boolean {
		return (this.$route.name === 'LegalNoticesPage' ||
			this.$route.name === 'TermsOfServicePage' ||
			this.$route.name === 'PrivacyPolicyPage' ||
			this.$route.name === 'DataProcessingAgreementPage' ||
			this.$route.name === 'CookiePolicyPage');
	}

	public get pendingWorkspaces(): Generated.SubSvcPendingWorkspaces {
		return this.pendingWorkspaceOrders;
	}

	public set pendingWorkspaces(workspaces: Generated.SubSvcPendingWorkspaces) {
		this.pendingWorkspaceOrders = workspaces;
	}

	public get skipWorkspaceCreationDialog(): boolean {
		return this.$tsStore.settings.skipWorkspaceCreationDialog;
	}

	// ######################## Methods ########################
	/**
	 * Event handler for the set-language event emitted by BaseUI.
	 * We need to make sure to only update the user if his language has actually changed to prevent a call cycle.
	 * @param lang Language that was set.
	 * @author OK
	 */
	public async onSetLanguage(lang: LanguageCode): Promise<void> {
		const user = this.$tsStore.users.user;

		if (user && user.Language !== lang) {
			await this.$tsStore.users.updateSingle({
				uuid: user.UUID,
				attributes: {
					Language: lang,
				},
			});
		}
	}

	public resetProjectsFetchResult() {
		this.projectsFetchResult.loading = false;
		this.projectsFetchResult.errorMsg = null;
	}

	public resetWorkspacesFetchResult() {
		this.workspacesFetchResult.loading = false;
		this.workspacesFetchResult.errorMsg = null;
	}

	public async initializeSettings() {
		try {
			await this.$tsStore.settings.initializeSettings();
		} catch (error: any) {
			this.errors.push({ error, message: 'LP_ERR_GET_SETTINGS' });
		}
	}

	/**
	 * Recursive function that waits until the route un the url has a defined name.
	 * Once we know the name of the route we can know how to better initialize the page,
	 * therefore the promise will be resolved was the name is defined.
	 */
	public waitForRouteToBeDefined(): Promise<true> {
		// Keep the resolved status
		let resolved = false;
		return new Promise((resolve) => {
			const checkRoute = () => {
				if (this.$route.name) {
					resolved = true;
					resolve(true);
					return;
				}
				if (!resolved) {
					// Only call the function recursively if it wasn't already resolved,
					// otherwise it was calling it indefinitely.
					setTimeout(checkRoute, 1);
				}
			};
			checkRoute();
		});
	}

	/**
	 * Gets the workspace UUID from the route, for the completion of a pending workspace order.
	 * @author PB
	 * @returns Workspace UUID or null.
	 */
	public getPendingWorkspaceUuidFromRoute(): string | null {
		return validateStringIfDefined(this.$route?.query?.wid) ?? null;
	}

	/**
	 * Handles the UUID of a pending workspace to complete a workspace activation.
	 * @author PB, OPC
	 * @param workspaceUuid UUID of the workspace.
	 * @param hasMultiplePendingWorkspaces Optional flag. Set to true if the user has more than one pending workspace.
	 */
	public async handlePendingWorkspaceUuid(workspaceUuid: string, hasMultiplePendingWorkspaces?: boolean) {
		try {
			await this.$tsStore.workspaces.getSingle(workspaceUuid);
			const workspace: Workspace = this.$tsStore.workspaces.ItemsMap[workspaceUuid];
			if (workspace.State === 'pending') {
				this.$faroTaskService.showTask<InitData>(UpdateOrCreateWorkspaceTask, {
					workspace,
					isFullscreen: false,
					skippable: undefined,
					hasMultiplePendingWorkspaces,
				});
			}
		} catch (error: any) {
			this.errors.push({ error, message: 'LP_ERR_GET_WORKSPACE' });
		}
	}

	/**
	 * Shows a dialog task to the user where they can select a pending workspace to complete it.
	 */
	public selectPendingWorkspace() {
		this.$faroTaskService.showTask<SelectWorkspaceInitData>(UpdateOrCreateWorkspaceTaskSelect, {
			pendingWorkspaces: this.pendingWorkspaces,
			isFullscreen: false,
		});
	}

	/**
	 * Handles any pending or active workspaces from the Subscription Service and AuthZ.
	 * @author PB, OPC
	 */
	public async handleUserWorkspaces() {
		try {
			// Request a new Token. Completing a singup process on the the original browser tab seems to return a Token with email_verified
			// set to false even when the user email has been verified. See: https://faro01.atlassian.net/browse/FC-5501
			// [Update] This doesn't matter any more, since SubSvc no longer checks the "email_verified" claim. -> Removed { ignoreCache: true }.
			const $tsStore: $tsStore = Vue.prototype.$tsStore;
			await $tsStore.users.getTokenSilently();

			// Perform workspace creation check: validate if user is not the InitialOwner(creator) of a workspace. Get pending workspaces.
			// Pending workspaces were created by an order in SubSvc and have not been completed and activated by the user yet.
			const result = await this.$tsStore.users.validateWorkspaceCreation();
			const userIsNotIntialOwnerOfAWorkspace: boolean = result.CreateWorkspaceCheck;
			this.pendingWorkspaces = result.PendingWorkspaces;

			// Get user member workspaces (non demo)
			const memberWorkspaces = await this.$tsStore.workspaces.itemsList.filter(workspace => workspace.IsDemo === false);

			// If fetching the workspaces has failed, don't treat this as the "zero workspaces" case below.
			const failed = !!this.workspacesFetchResult.errorMsg;

			// Count deactivated workspaces the same way as active ones.
			// -> If we've deactivated a user's workspace, they won't be able to create a new Base workspace.
			// -> There may be the case that the user doesn't see any workspaces on the "Workspaces" page, but can't create a new one.
			// This is fully intended, since there was probably a good reason why FARO has deactivated the workspace.

			if (!failed && this.pendingWorkspaces.length === 0 && userIsNotIntialOwnerOfAWorkspace && memberWorkspaces.length === 0) {
				// The user has no pending workspaces, is not an initial owner of a workspace, and is not a member(or a pending member) of a workspace
				// Force the user to create a workspace
				this.workspaceCreationTaskVisible = true;
				this.$faroTaskService.showFullscreenTask<InitData>(UpdateOrCreateWorkspaceTask,
					{ workspace: undefined, isFullscreen: true, skippable: false });
			} else if (this.pendingWorkspaces.length === 1 && this.pendingWorkspaces[0].Id) {
				// The user has one pending workspace in SubSvc. Give user the option to complete the pending workspace.
				await this.handlePendingWorkspaceUuid(this.pendingWorkspaces[0].Id);
			} else if (this.pendingWorkspaces.length > 1) {
				// The user has more than one pending workspace in SubSvc. Give user the option to select a workspace to complete.
				this.selectPendingWorkspace();
			}
		} catch (error: any) {
			const result = error as HttpError;
			if (result.url && StringUtils.endsWith(result.url, 'create-workspace-check')) {
				this.errors.push({ error, message: 'LP_ERR_WORKSPACE_CREATION_CHECK' });
			} else {
				this.errors.push({ error, message: 'LP_ERR_GET_WORKSPACES' });
			}
		}
	}

	public async initializeWorkspaces() {
		try {
			this.workspacesFetchResult.loading = true;
			await this.$tsStore.workspaces.getAll();
			await this.$tsStore.workspaces.checkDomainCreationStatusForAllWorkspaces();
		} catch (error: any) {
			console.error(error);
			this.workspacesFetchResult.errorMsg = getErrorMessage(error);
			this.errors.push({ error, message: 'LP_ERR_GET_WORKSPACES' });
		} finally {
			this.workspacesFetchResult.loading = false;
		}
	}

	/**
	 * Initializes the subscription services and takes care of reading the user pending workspaces.
	 */
	public async initializeSubscriptionService() {
		const workspaceUuid = this.getPendingWorkspaceUuidFromRoute();
		if (workspaceUuid) {
			await this.handlePendingWorkspaceUuid(workspaceUuid);
		} else {
			await this.handleUserWorkspaces();
		}
	}

	public async initializeProjects() {
		// initializeActiveWorkspace already cared for the case if the demo workspace is active.
		if (this.$tsStore.workspaces.isDemoActive) {
			return;
		}

		try {
			this.projectsFetchResult.loading = true;
			await this.$tsStore.projects.getAll();
		} catch (error) {
			this.projectsFetchResult.errorMsg = getErrorMessage(error);
			console.error(error);
		} finally {
			this.projectsFetchResult.loading = false;
		}
	}

	public async initializeUserPermissions() {
		try {
			await this.$tsStore.users.initialize();
			this.initialized = true;
		} catch (error) {
			this.errorTitle = this.$tc('LP_ERR_ACCESS_LANDING_PAGE');
			this.errorMsg = getErrorMessage(error);
			this.criticalError = true;
		}
	}

	/**
	 * Sets the active workspace on page load and also updates the user setting accordingly if necessary.
	 * @author OK
	 */
	public async initializeActiveWorkspace(): Promise<void> {
		try {
			// The workspace UUID from the route of migration pages must not be set as active workspace,
			// since the current user will very often not be able to access it.
			// This would cause the SelectWorkspacePage to be shown.
			const isMigrationPage = this.$route.path?.startsWith('/migration');
			const uuidFromRoute = isMigrationPage ? undefined : this.$route.params.workspace;
			// throws HttpError, Error
			await this.$tsStore.workspaces.initializeActiveWorkspace({ uuidFromRoute, $router: this.$router });

			const activeWorkspace = this.$tsStore.workspaces.activeWorkspace;
			const workspaces = this.$tsStore.workspaces.itemsList;
			// Make sure that there's no redirect if the user just signed up (only demo workspace),
			// or still has a pending workspace to set up (getPendingWorkspaceUuidFromRoute or State === 'pending').
			if (config.allowRedirect && !isMigrationPage && !this.getPendingWorkspaceUuidFromRoute()) {
				if (uuidFromRoute && uuidFromRoute === activeWorkspace?.UUID && activeWorkspace?.XgRedirect) {
					console.log('Workspace in URL redirects to XG:', uuidFromRoute);
					redirectToXg(activeWorkspace?.Region);
				} else if (workspaces.length > 0 &&
					workspaces.some((workspace) => workspace.XgRedirect && !workspace.IsDemo && workspace.isActive) &&
					workspaces.every((workspace) => workspace.XgRedirect || workspace.IsDemo || workspace.State === 'deactivated')
				) {
					console.log('All user\'s workspaces redirect to XG.');
					redirectToXg(activeWorkspace?.Region || workspaces[0].Region);
				}
			}
		} catch (error: any) {
			this.errors.push({ error, title: 'LP_ERR_GET_WORKSPACE_TITLE' });
		}
	}

	public startConnection() {
		(async () => {
			try {
				await this.$tsStore.projectStatuses.startConnection();
			} catch (error) {
				console.error(error);
				this.$tsStore.projectStatuses.showProjectStatusError(error);
			}
		})();
	}

	public initializeProjectStatuses() {
		// We don't want to wait for the promise to be resolved because startConnection
		// will only resolve if the connection is interrupted.
		setTimeout(this.startConnection);
	}

	public async skipSphereXgInfoDialog(openLink: boolean) {
		const user = this.$tsStore.users.user;
		$assert.Assert(!!user, 'skipSphereXgInfoDialog: user is undefined');
		if (user) {
			// Don't be tempted to await the updateSingle() call, as it would lead to some browsers (Firefox) blocking the window.open() call.
			// Opening a new tab must be done from synchronous code in an event/click handler.
			this.$tsStore.users.updateSingle({
				uuid: user.UUID,
				attributes: {
					SphereXgInfoViewed: true,
					SphereXgInfoClickedLink: openLink ? true : undefined, // Using undefined to omit the attribute in the request body.
				},
			}).catch((error) => { console.error(error); });
		}
		// Hide the dialog:
		this.showSphereXgInfoDialog = false;
		if (openLink) {
			const sphereXgLink = 'https://www.holobuilder.com/faro-sphere-xg-upgrade-callback/';
			window.open(sphereXgLink, '_blank')?.focus();
		}
	}

	public initializeFilters(userUUID?: string) {
		// Initialize all stores if they have stored filters
		let storeName: keyof $tsStore;
		for (storeName in this.$tsStore) {
			const store = this.$tsStore[storeName];
			if (store instanceof BaseFilterModule) {
				store.readPageFilter(userUUID);
			}
		}
	}

	/**
	 * Validation if we need to show the Sphere XG migration dialog.
	 */
	public async checkShowSphereXgInfoDialog() {
		const showSphereXgDialog = await isFeatureEnabledByAuth0Token('showSphereXgDialog');
		const user = this.$tsStore.users.user;

		if (showSphereXgDialog && user && !user.SphereXgInfoViewed) {
			this.showSphereXgInfoDialog = true;
		}
	}

	public async initialize() {
		await this.waitForRouteToBeDefined();

		this.$tsStore.pages.initialize(this.$route.name ?? null);

		if (this.isFullscreenTaskPage || this.isLegalNoticesPage) {
			this.$faroLoading.stop();
			return;
		}

		await this.$tsStore.users.getTokenSilently();
		const decodedToken = this.$tsStore.users.decodedToken;
		const userUUID = decodedToken['https://farosphere.com/userid'];

		if (userUUID) {
			// Only initialize filters if user UUID is already available.
			this.initializeFilters(userUUID);
		}

		this.$tsStore.workspaces.setDemoWorkspace();

		// Initializing workspaces must be done before initializing projects, otherwise projects won't open correctly
		// when entering the page via /home/projects.
		await Promise.all([
			this.initializeSettings(),
			this.initializeWorkspaces(),
		]);
		await Promise.all([
			this.initializeSubscriptionService(),
			this.initializeUserPermissions(),
			this.initializeActiveWorkspace(), // Requires both initializeWorkspaces, initializeSettings.
		]);
		await this.initializeProjects(); // Requires initializeActiveWorkspace.
		this.initializeProjectStatuses();

		if (!userUUID) {
			// If user UUID was not available before, then initialize the filters after getting all services..
			this.initializeFilters();
		}

		this.$tsStore.pages.setFinishedMainLoading(true);
		if (this.$tsStore.pages.finishedPageLoading) {
			this.$faroLoading.stop();
		}

		// TODO remove in a couple weeks (created on 07.09.2022), this code takes care of cleaning the old filters.
		try {
			for (let i = 0; i < localStorage.length; i++) {
				const key = localStorage.key(i);
				if (key && (key.startsWith('fc$LANDINGPAGE$filters-') || key.startsWith('fc$LANDINGPAGE$saveFilters-'))) {
					localStorage.removeItem(key);
					// i has to decreased by one because the current key has been removed.
					i--;
				}
			}
		} catch (error) {
			// Ignore the error for now
			console.error(error);
		}

		await this.checkShowSphereXgInfoDialog();

		this.pollActiveWorkspace();
	}

	/**
	 * Poll every 60 seconds for changes to the active workspace, in particular its ReadOnly and XgRedirect flags.
	 * @author OK
	 */
	protected pollActiveWorkspace(): void {
		setInterval(async () => {
			const activeWorkspace = this.$tsStore.workspaces.activeWorkspace;
			// Don't poll if the Landing Page isn't shown or there's no active workspace.
			if (document.hidden || !activeWorkspace) {
				return;
			}

			const readOnlyOld = activeWorkspace.ReadOnly;
			await this.$tsStore.workspaces.retrieveActiveWorkspace();
			this.$tsStore.migrationWorkspaces.setReadOnlyState({
				workspaceUuid: activeWorkspace.UUID,
				readOnly: activeWorkspace.ReadOnly,
				xgRedirect: activeWorkspace.XgRedirect,
			});

			// If still the same workspace is active and its read-only state has changed, make sure the message is
			// displayed again if the user previously hid it.
			if (activeWorkspace.UUID === this.$tsStore.workspaces.activeWorkspace?.UUID &&
				readOnlyOld !== this.$tsStore.workspaces.activeWorkspace?.ReadOnly
			) {
				this.hasClosedReadOnlyMsg = false;
			}
		}, 60000);
	}

	public handleLogoClicked() {
		const fromLegal: boolean = StringUtils.startsWith(this.$route.path, '/legal/');
		this.$router.push({ name: 'HomePage'});
		// If clicked from legal notices page, a reload needs to be forced, otherwise it wont load home page
		if (fromLegal) {
			this.$router.go(0);
		}
	}

	// ######################## Lifecycle hooks ########################

	public async created() {
		this.$faroComponents.$on('closereadonlymsg', () => {
			this.hasClosedReadOnlyMsg = true;
		});
		this.$faroComponents.$on('scheduledmsgready', () => {
			this.isScheduledNoteShown = true;
		});
		this.$faroComponents.$on('closescheduledmsg', () => {
			this.isScheduledNoteShown = false;
		});
		this.$faroComponents.$on('set-language', this.onSetLanguage);
		this.$faroComponents.$on('pending-workspace-selected', (workspaceUuid: string) => {
			this.handlePendingWorkspaceUuid(workspaceUuid, true);
		});
		this.$faroComponents.$on('select-pending-workspace', () => {
			this.selectPendingWorkspace();
		});
		this.$faroComponents.$on('close-create-workspace-task', () => {
			this.workspaceCreationTaskVisible = false;
		});
		this.$faroComponents.$on('show-error', (errorInfo: ErrorInfo) => {
			if (errorInfo.error && !errorInfo.error.traceId) {
				const eHttp = errorInfo.error as HttpError;
				if (eHttp.responseBody?.requestId) {
					// Set CoreAPI request ID as trace ID.
					errorInfo.error.traceId = 'HB: ' + eHttp.responseBody?.requestId + ' (requestId)';
				}
			}
			this.errors.push(errorInfo);
		});
	}

	public mounted() {
		this.$tsStore.pages.setMdAndUp(this.$vuetify.breakpoint.mdAndUp);
		this.$faroLoading.start();
		// To make sure that the initialize() tasks wait until the $faroLoading.start() finishes and the thread is available.
		// A more appropriate explanation can be found here https://stackoverflow.com/questions/9083594/call-settimeout-without-delay
		setTimeout(this.initialize);
		// Have the search bar hidden by default in mobile devices
		this.$tsStore.pages.setSearchBarMobileVisible(this.$vuetify.breakpoint.mdAndUp);
	}

	// Watch for breakpoint to trigger show/hide searchBar.
	@Watch('$vuetify.breakpoint.mdAndUp')
	public onMdAndUpChanged(mdAndUpNew: boolean, mdAndUpOld: boolean) {
		this.$tsStore.pages.setMdAndUp(mdAndUpNew);
		// If the screen is resized to be small hide the search bar by default
		if (mdAndUpOld && !mdAndUpNew) {
			this.$tsStore.pages.setSearchBarMobileVisible(false);
		}
	}
}
